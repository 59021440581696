import React from "react"
import Box from "@mui/material/Box"
import Header from "../components/Header"

import image1 from "../assets/images/header-bg.jpg"
import image2 from "../assets/images/header-01.png"

const Solution = () => {
  return (
    <Box>
      <Header
        bgImage={image1}
        subjectImage={image2}
        title="提供一站式网站建设、网络营销服务"
        subtitle="篆文建站，您身边的建站专家！"
      />
      <Box sx={{ height: "50vh" }}></Box>
    </Box>
  )
}

export default Solution
